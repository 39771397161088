interface DisallowWordData {
  raw: string;
  added: string;
  addedBy: string;
  modified?: string;
  modifiedBy?: string[];
  source?: string[];
}
export class DisallowWord {
  raw: string;
  added: string;
  addedBy: string;
  modified?: string;
  modifiedBy?: string[];
  source?: string[];

  constructor(data: DisallowWordData) {
    this.raw = data.raw;
    this.added = data.added;
    this.addedBy = data.addedBy;
    this.modified = data.modified;
    this.modifiedBy = data.modifiedBy;
    this.source = data.source;
  }
}
